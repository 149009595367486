.blog-title {
    padding-top: 40px;
}

.similar-wrapper {
    text-align: center;
}

.similar-posts {
    display: inline-block;
}

section.blog-wrapper {

    .blog {
        margin-top: 30px;
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        
        .blog-card {
            width: 320px;
            height: 100%;
            background-color: #ffffff;
            margin: 15px 15px;

            &:hover,
            &:focus {
                transition: .15s ease-in-out;
                background-color: #f5f5f5;
                box-shadow: 0px 0px 20px 0px rgba(51,51,51,0.15);
            }

            .blog-background {
                width: 320px;
                weight: 180px;
            
                .blog-background-image {
               
                }

                .blog-image {
                    width: 320px;
                    height: 100%;
                }
            }

            .blog-content {
                padding: 20px;
                text-align: left;

                .card-title {
                    color: #333;
                    margin: 0 0 10px 0;
                    padding-top: 0;
                    text-transform: uppercase;
                }

                .card-text {
                    font-size: 1em;
                    color: #666;
                }

                .blog-line {
                    height: 1px;
                    width: 100%;
                    background-color: #f0ebef;
                    margin: 20px 0;
                }

                .post-info {
                    display: flex;   
                    align-items: center;     

                    .blog-avatar {
                       margin-right: 20px;
                    }

                    .avatar {
                        width: 70px;
                        height: 70px;
                    }

                    .info-text { 

                        .blog-writer {
                            margin: 0;
                            color: #333;
                        }
                
                        .blog-date {
                            font-size: 1em;
                            color: #999;
                        }
                    }
                }
            }
        }
    }

    .pagination {
        text-align: center;
        margin-bottom: 60px;

        ul.page-numbers {
            list-style: none;
            display: block;
            font-size: 0;
            text-align: center;
            user-select: none;
            padding-left: 0px;

            & > li {
                display: inline-block;
                margin: 4px;
                font-size: 1rem;

                .page-numbers {
                    display: block;
                    width: 44px;
                    border: 1px solid #999;
                    font-size: 1.2em;
                    line-height: 40px;
                }

                span {

                    &.current {
                        border-color: transparent;
                        background-color: #f04e23;
                        color: #fff;
                        font-weight: 600;
                    }

                    &.dots {
                        background-color: #d6d2d5;
                        color: #000;
                    }
                }

                a {
                    text-decoration: none;
                    color: #000;

                    &:hover,
                    &:focus {
                        border-color: #000;
                    }

                    &.prev {}

                    &.next {}
                }
            }
        }
    }
}

.blog-detail-page {

    .blog-detail {

        .blog-title {
            font-size: 3em;
            margin-bottom: 30px;
        }

        .post-info {
            display: flex;
            align-items: center;

            .blog-avatar {
                margin-right: 20px;

                .avatar {
                    width: 80px;
                    height: 80px;
                }
            }

            .blog-info-text {

                .blog-writer {
                    
                }

                .blog-time-info {
                    display: flex;
                    flex-wrap: wrap;

                    .text-separator {
                        height: 3px;
                        width: 3px;
                        border-radius: 50%;
                        background-color: #000;
                        margin: 10px 15px;
                    }

                    .blog-date {
                        font-size: 1em;
                        color: #999;
                    }

                    .blog-estimated-time {
                        font-size: 1em;
                        color: #999;
                    }
                }
            }
        }

        .blog-line {
            height: 1px;
            width: 100%;
            background-color: #dbd7da;
            margin: 15px 0;
        }

        .blog-article {
            margin-bottom: 60px;;

            .article-image {
                width: 100%;
                margin: 15px 0;
            }
        }

        .post-article {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .tags {

                .tag-title {

                }

                .tag-grid {
                    list-style: none;
                    padding: 0;

                    li {
                        display: inline-block;
                    }

                    .tag {
                        color: #fff;
                        background-color: #f04e23;
                        display: block;
                        padding: 8px 20px;
                        border-radius: 4px;
                        margin: 2px 2px;

                        &:hover,
                        &:focus {
                            background-color: #f26039;
                        }
                    }
                }
            }

            .share {

                .share-title {
                    text-align: right;
                }

                .share-grid {
                    text-align: right;
                    list-style: none;
                    padding: 0;

                    li {
                        display: inline-block;
                    }

                    .social-icon {
                        color: #000;
                        box-shadow: inset 0 0 0 1px #000;
                        display: block;
                        width: 36px;
                        height: 36px;
                        text-align: center;
                        line-height: 36px;
                        border-radius: 50%;
                        margin: 2px 2px;

                        &:hover,
                        &:focus {
                            background-color: #000;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

/* == Custom == */
@media only screen and (max-width : 480px) {

    .blog-detail-page {

        .blog-detail {

            .blog-title {
                font-size: 1.7em;
            }
        }

        h2 {
            font-size: 1.5em;
        }
    }
}

/* == Custom == */
@media only screen and (max-width : 350px) {

    section.blog-wrapper.blog-wrapper {

        .blog {
            
            .blog-card {
                width: 300px;
                margin: 15px 5px;

                .blog-background {
                    width: 300px;
                    weight: 180px;
                
                    .blog-background-image {}

                    .blog-image {
                        width: 300px;
                    }
                }
            }
        }
    }
}