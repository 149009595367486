/******************************

    Roi page styling

******************************/

#roi {

  #report {
    float: left;
    width: 100%;
    border: 1px solid scale-color($grey-color, $lightness: 85%);
    border-right-width: 2px;
    border-bottom-width: 2px;
    padding: 30px 15px;
    margin-top: 30px;

    h3 {
      margin: 10px 0 30px 0;
      color: $orange-color;
    }

    h4 {
      margin: 0;
    }

    #grade {
      @include default-easing;
      padding: 30px;
      border-width: 1px;
      border-style: solid;
      float: left;
      width: 100%;
      margin: 30px 0 -15px 0;
    }

    #grade .fa.icon {
      position: absolute;
      right: 20px;
      top: -20px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 23px;
      color: #fff;
      border-radius: 6px;
    }

    #grade .fa.icon.fa-check {
      background: $brand-success;
    }

    #grade .fa.icon.fa-warning {
      background: $brand-warning;
    }

    #grade.valid {
      border-color: $brand-success;
      background: scale-color($brand-success, $lightness: 80%);
      h3 {
        color: scale-color($brand-success, $lightness: -40%);
      }
    }

    #grade.invalid {
      border-color: $brand-warning;
      background: scale-color($brand-warning, $lightness: 80%);
      h3 {
        color: scale-color($brand-warning, $lightness: -40%);
      }
    }

    #grade .valid-text,
    #grade .invalid-text {
      display: none;
      font-weight: 600;
      font-size: 13px;
      padding-top: 20px;
    }

    #grade.valid .valid-text {
      display: block;
      color: scale-color($brand-success, $lightness: -40%);
    }

    #grade.invalid .invalid-text {
      display: block;
      color: scale-color($brand-warning, $lightness: -40%);
    }

    #grade tr td, #grade tr th {
      padding-left: 0;
      padding-right: 0;
    }

  }

}