@media (max-width: #{$screen-md-max}) {

  footer {
    text-align: center !important;
    .pull-right {
      float: none !important;
    }
  }

  footer {
    #social {
      margin-bottom: 20px;
    }
    .cta {
      margin-top: 20px;
    }
  }

}