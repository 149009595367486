@media (max-width: #{$screen-xs-max}) { 
  // Menu
  #menu {
    width: 100%;
    border-right: 0;
    left: -100%;
  }

  // Pages
  #title .menu {
    display: none;
  }

  #content .menu {
    display: block;
  }

  #title {
    padding: 30px;
  }

  .tree:after {
    display: none !important;
  }

  // Promo
  #promo {
    height: 350px;
    .sticky {
      bottom: 50px;
    }
  }

  // Concepts
  #concepts {
    .concept {
      margin-bottom: 30px;
    }
  }

  // Header

  header #logo {
    display: none;
  }
}