/******************************

    Body

******************************/

body {
  font-family: 'Lato';
  background: #f0ebef;
  color: $grey-color;
  font-size: 14px;
}

body.scrolled {
  header {
    height: 60px;
    padding: 0 15px;
    .cta {
      margin: 15px 0 0;
    }
    .menu-button {
      margin-top: 15px;
    }
    #logo {
      line-height: 50px;
      img {
        height: 29px;
      }
    }
  }

  #menu {
    padding-top: 59px;
    .close {
      top: 16px;
      right: 15px;
    }
  }
}

body.loading-state {
  #menu #loader {
    display: block;
  }
}

body.menu-open {
  overflow: hidden;

  #page {
    margin-left: $menu-width;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: fixed;
      background: $grey-color;
      opacity: .8;
      left: 0;
      top: 0;
      pointer-events: none;
      z-index: 2;
    }
  }

  #menu {
    left: 0;
  }

  header {
    margin-left: $menu-width;
    width: 100%;
    .menu-button {
      color: $primary-color;
    }
  }
}

/******************************

    Grid

******************************/

.row {
  &.collapse {
    [class*="md-"], [class*="lg-"], [class*="sm-"], [class*="xs-"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

/******************************

    Typography

******************************/

h1 {
  font-size: 2.8em;
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 2.4em;
  border-bottom: 2px $primary-color solid;
  display: inline-block;
  margin-bottom: 40px;
}

p {
  line-height: 1.8em;
  font-size: 1.3em;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

/******************************

    Light and dark

******************************/

.light {
  background: #fff;
  color: $grey-color;
  &.tree:after {
    left: 60px;
    background: url(img/tree_black.png) center no-repeat;
  }
}

.dark {
  background: #000;
  color: #fff;
  &.tree:after {
    right: 60px;
    background: url(img/tree_white.png) center no-repeat;
  }
}

.light,
.dark {
  &.tree {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 109px;
      height: 119px;
      bottom: 0;
    }
  }
}

/******************************

    Overall fixes

******************************/

iframe {
  border: none;
}

.clearfix {
  clear: both;
}

.container-fluid {
  padding: 0;
}

.img-responsive {
  display: inline-block;
}

/******************************

    Cta button

******************************/

a.cta {
  display: inline-block;
  height: 32px;
  border: 1px $primary-color solid;
  line-height: 30px;
  padding: 0 30px;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  &:hover {
    background: $primary-color;
    color: #fff;
  }
}

/******************************

    Default orange button

******************************/

a.button,
input[type=submit].button {
  background: $primary-color;
  padding: 10px 60px;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  border: none;
  @include default-easing;
  &:hover:not(:active) {
    background: scale-color($primary-color, $lightness: 10%);
  }
}

a.cta:before {
  display: inline-block;
  width: 30px;
}

/******************************

    Menu

******************************/

#menu {
  width: $menu-width;
  float: left;
  height: 100%;
  background: $menu-bg;
  position: fixed;
  left: -#{$menu-width};
  z-index: 5;
  border-right: #7d7b7b 1px solid;
  color: #fff;
  padding-top: 79px;
  overflow-y: auto;

  .close {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    top: 25px;
    right: 25px;
    background: #000;
    line-height: 30px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      background: $primary-color;
    }
  }

  li {
    list-style: none;
  }

  ul.menu {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    border-top: 1px scale-color($menu-bg, $lightness: 15%) solid;
    li {
      &.current_page_item,
      &.current_page_parent {
        a {
          background: scale-color($menu-bg, $lightness: -15%);
          color: #fff;
        }
      }
      &:not(.current_page_item):hover a {
        color: $primary-color;
        background: scale-color($menu-bg, $lightness: 5%);
      }
      a {
        border-bottom: 1px #4c4a4b solid;
        padding: 12px 20px;
        display: block;
        color: #fff;
      }
    }
  }

  .cta {
    width: 100%;
    padding: 15px;
    margin: 0 0 20px 0;
    background: $primary-color;
    height: auto;
    line-height: 1;
    text-align: center;
  }

  .second-menu {
    padding: 30px;
    a {
      &:not(.cta) {
        color: scale-color($menu-bg, $lightness: 50%);
        padding: 5px 0;
        display: block;
        &:hover {
          color: $primary-color;
        }
        span:before {
          display: inline-block;
          width: 20px;
        }
      }
    }
  }

  #loader {
    position: absolute;
    left: 25px;
    top: 25px;
    width: 32px;
    height: 32px;
    display: none;
  }

}

/******************************

    Page

******************************/

#page {
  padding-top: $header-height;
  width: 100%;
}

/******************************

    Header

******************************/

header {
  height: $header-height;
  background: #000;
  border-bottom: 1px #7d7b7b solid;
  color: #fff;
  padding: 0 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  white-space: nowrap;

  #logo {
    font-size: 30px;
    line-height: $header-height - 10;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    top: 0;
    z-index: 1;
    img {
      height: 36px;
    }
  }

  .cta {
    margin: 24px 0 0 0;
    position: relative;
    z-index: 2;
  }

  .menu-button {
    color: #fff;
    display: block;
    height: 32px;
    margin: 24px 0 0 0;
    line-height: 32px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 1.1em;
    &:hover {
      color: $primary-color;
    }
    span:before {
      display: inline-block;
      width: 25px;
      text-align: left;
    }
  }
}

/******************************

    Footer

******************************/

footer {
  padding: 60px 30px;
  line-height: 30px;
  #social a {
    display: inline-block;
    width: 32px;
    height: 32px;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
    text-align: center;
    line-height: 32px;
    margin-right: 8px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      background: #fff;
      color: #000;
      box-shadow: none;
    }
  }
  a {
    color: $orange-color;
  }
}

/******************************

    Breadcrumbs

******************************/

.breadcrumbs a {
  color: $primary-color;
}

/******************************

    Gravity forms

******************************/

.gform_body {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
  .gfield_required {
    color: $orange-color;
    margin-left: 5px;
  }
  .gfield_description {
    font-size: .8em;
    font-style: italic;
    color: scale-color($grey-color, $lightness: 50%);
  }
}

/******************************

    Animation

******************************/

#menu,
header,
#page {
  @include default-easing(500ms);
}