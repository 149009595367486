p.text {
  margin-bottom: 60px;
}

#front-page {

  .backstretch {
    img {
      bottom: 0;
      top: auto !important;
    }
  }

  #promo,
  #about,
  #concepts,
  #invest,
  #dragons-den,
  #testimonials {
    padding: 60px 15px 80px 15px;
  }

  /******************************

      Promo

  ******************************/


  #promo {
    height: 570px;
    text-align: center;
    position: relative;

    .sticky {
      position: absolute;
      bottom: 100px;
      left: 0;
    }

    h1 {
      color: #fff;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
    }
  }

  #promo,
  #invest {
    .button {
      margin-top: 30px;
    }
  }

  /******************************

      About

  ******************************/

  #about {

    img {
      max-width: 160px;
      max-height: 160px;
      @include default-easing(500ms);
    }

    span,
    small {
      display: block;
      text-transform: uppercase;
    }

    span {
      font-size: 1em;
      margin: 20px 0 0;
    }

    small {
      color: $primary-color;
      opacity: .6;
      font-size: .8em;
      margin-bottom: 40px;
    }

    .profile {
      color: $grey-color;
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  /******************************

      Concepts

  ******************************/

  #concepts {
    p {
      color: #fff;
    }

    .concept {
      position: relative;
      overflow: hidden;
      background: #fff;
      display: block;
      color: #fff;
      border: 1px #fff solid;
    }
  }

  /******************************

      Invest online

  ******************************/

  #invest:after {
    right: 60px;
    left: auto;
  }

  /******************************

      Dragon's Den

  ******************************/

  #dragons-den {
    img {
      margin-bottom: 100px;
    }
  }

  /******************************

      Testimonials

  ******************************/

  #testimonials {
    padding-bottom: 200px;

    p {
      font-size: 2.2em;
      &:before {
        content: '\201C';
      }
      &:after {
        content: '\201D';
      }
      &:before, &:after {
        color: $primary-color;
      }
    }
  }
}

/* == Custom == */
@media only screen and (max-width : 1000px) {

  #front-page {

    .dark .tree {
      display: flex;
      align-items: center;
    }

    #promo {

      .sticky {
        bottom: 45%;
      }
    }
  }
}