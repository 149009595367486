#title,
#content {
  padding: 60px 30px;
}

/******************************

      Content

******************************/

#content {
  line-height: 1.8em;
  font-size: 1.3em;

  &:not(.dark) {
    background: #fff;
  }

  @media (min-width: #{$screen-xs-max}) {
    padding-bottom: 200px;
  }

  p, ul, ol {
    margin-bottom: 30px;
  }

  p {
    font-size: 1em;
  }

  blockquote {
    border: none;
    margin: 20px 0 40px 0;
    text-align: center;
    padding: 0;
    color: $primary-color;
    font-size: 1.6em;
  }

  .menu {
    display: none;
    font-size: .7em;
    margin-top: -30px;
    ul {
      margin: 0 0 40px 0;
      padding: 0;
      border: 1px #d9d9d9 solid;
      li {
        list-style: none;
        border-bottom: 1px #d9d9d9 solid;
        &:last-child {
          border-bottom: none;
        }
        &.current_page_item {
          a {
            color: $primary-color;
            font-weight: 700;
          }
        }
        a {
          padding: 10px 15px;
          display: block;
          text-transform: uppercase;
          color: #333132;
        }
      }
    }
  }

  #cta-block {
    background: scale-color($grey-color, $lightness: 90%);
    border-bottom-width: 2px;
    padding: 52px;
    margin-top: 30px;
    float: left;
    width: 100%;

    h4 {
      margin: 0;
      line-height: 52px;
      font-size: 1.2em;
      @media (max-width: #{$screen-sm-max}) {
        text-align: center;
        line-height: 1.1em;
        margin-bottom: 30px;
      }
    }

    .button {
      display: block;
      width: 100%;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  #sidebar {
    .block {
      float: left;
      width: 100%;
      font-size: .75em;
      padding: 20px;
      border: scale-color($grey-color, $lightness: 90%) 1px solid;

      &:not(:last-child) {
        border-bottom: none;
      }

      h4 {
        font-weight: 700;
        padding: 0 0 8px 0;
        margin-top: 0;
        text-transform: uppercase;
        font-size: 1em;
      }
      p, ul, ol {
        &:last-child {
          margin-bottom: 0;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      ul, ol {
        list-style: none;
        list-style-type: inside;
        padding: 0;
      }
      a {
        color: $orange-color;
      }

      &.widget_omgekeerde_roi {
        .field {
          float: left;
          width: 100%;
          input {
            width: 100%;
          }
          label {
            color: $orange-color;
          }
        }

        input[type=submit] {
          border: none;
          width: 100%;
          text-align: center;
          display: block;
        }
      }
      &.widget_dragons_den {
        border: none;
        margin-bottom: 20px;
        background: $orange-color;
        color: #fff;
        text-align: center;
        font-size: 1.1em;
        padding: 0;
        font-weight: 300;
        border-bottom: 3px $gray solid;
        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 20px;
          color: #fff;
        }
        h4 {
          font-weight: 700;
          font-size: 1.2em;
        }

        &:hover {
          background: scale-color($orange-color, $lightness: 20%);
        }
      }
    }
  }
}

/******************************

      Title

******************************/

#title {
  background-color: #000 !important;
  .menu {
    ul {
      padding: 0;
      margin: 30px 0 0 0;
      display: inline-block;
      li {
        list-style: none;
        float: left;
        border-top: 1px #4c4a4b solid;
        @include default-easing;

        &.current_page_item {
          border-top: 2px $primary-color solid;
          a {
            color: $primary-color;
          }
        }

        &:not(.current_page_item):hover {
          border-color: #fff;
        }

        a {
          display: block;
          height: 50px;
          line-height: 50px;
          padding: 0 20px;
          color: #fff;
        }
      }
    }
  }
}

/******************************

      Concepts

******************************/

#concept-blocks {
  border: 1px #fff solid;
  margin: -60px 0 0;
  .concept-item {
    height: 300px;
    a {
      color: $text-color;
      display: block;
      width: 100%;
      height: 100%;
    }
    h3 {
      font-weight: 700;
      color: $orange-color;
    }
    img {
      float: left;
      margin-right: 30px;
    }
    p {
      margin-bottom: 0;
      font-size: .9em;
    }
  }
}